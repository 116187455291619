<template>
  <div>
      <div class="container-fluid mt-lg-3">
          <div class="card">
              <div class="card-header bg-white">
                    <p class="d-inline-flex surah-name-id">{{dataSurah.name.transliteration.id}} ({{dataSurah.numberOfVerses}}) | {{dataSurah.name.translation.id}}</p>
                    <p class="d-inline-flex surah-name-id float-right">{{dataSurah.name.short}}</p>
              </div>
              <div class="card-body">
                  <div class="basmalah" v-if="dataSurah.preBismillah != null">
                      <p class="text-center">بِسْمِ اللَّهِ الرَّحْمَٰنِ الرَّحِيمِ</p>
                  </div>
                  <audio id="myAudio">
                    <source id="audioSource" :src="audio" type="audio/mpeg">
                    Your browser does not support the audio element.
                  </audio>

                  <div class="row border-bottom ayat-margin" v-for="(data,index) in dataSurah.verses" :key="index">
                        <div class="col-1" :id="'surah-'+data.number.inSurah">
                            <div class="dropdown">
                                <button class="btn btn-lg btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{data.number.inSurah}}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" @click="bookmark(data.number.inSurah,dataSurah.name.transliteration.id,dataSurah.numberOfVerses,dataSurah.name.translation.id,dataSurah.name.short)">
                                        <i class="fas fa-bookmark"></i> 
                                        Add to bookmark
                                    </a>
                                    <a class="dropdown-item"><i class="fas fa-book"></i> Tafsir</a>
                                    <a class="dropdown-item"><i class="fas fa-share"></i> Share</a>
                                    <a class="dropdown-item" @click="playAudio(data.audio.secondary[0])"><i class="fas fa-play-circle"></i> Play Audio</a>
                                    <a class="dropdown-item" @click="pauseAudio()"><i class="fas fa-stop-circle"></i> Stop Audio</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-11">
                            <div class="surah">
                                <div class="ayat-arabic">
                                    <p class="ayat-arabic text-right" dir="rtl">{{data.text.arab}}</p>
                                </div>
                                <p class="ayat-latin">{{data.text.transliteration.en}}</p>
                                <p class="ayat-indo">{{data.translation.id}}</p>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            dataSurah:{},
            audio:'https://cdn.islamic.network/quran/audio/128/ar.alafasy/1.mp3',
            tempBookmark:[],
            bookmarkParse:null
        }
    },
    watch: {
        $route: {
        immediate: true,
        handler() {
            this.getDataSurah()
        }
      }
    },
    mounted(){
        this.getDataSurah()

        var storedNames = JSON.parse(localStorage.getItem("bookmark"));

        if (storedNames == null) {
            console.log("no data in bookmarks");
        } else {    
            storedNames.forEach(element => {
                this.tempBookmark.push(element)    
            });
        }

        this.toBookmark()
    },
    methods:{
        getDataSurah(){
            this.axios.get(this.$url+'surah/'+this.$route.params.no)
            .then(response => {
                this.dataSurah = response.data.data;
            }).catch(err => {
                console.log(err);
            })
        },
        playAudio(audioSrc){
            this.audio = audioSrc
            var x = document.getElementById("myAudio"); 
            x.load();
            x.play(); 
            console.log(audioSrc);
        },
        pauseAudio(){
            var x = document.getElementById("myAudio"); 
            // var x = document.getElementById("myAudio"); 
            // document.getElementById('audioSource').src(audioSrc);
            x.pause(); 
            // console.log(audioSrc);
        },
        bookmark(ayat,surah,totalAyat,surahInd,surahArab){
            console.log(ayat);
            console.log(surah);
            console.log(totalAyat);
            console.log(surahInd);
            console.log(surahArab);

            this.tempBookmark.push({
                id:this.$route.params.no,
                ayat: ayat,
                surah: surah,
                totalAyat: totalAyat,
                surahInd: surahInd,
                surahArab: surahArab,
            })

            let parsed = JSON.stringify(this.tempBookmark);
            localStorage.setItem('bookmark', parsed);

            const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    }
            })

            Toast.fire({
                icon: 'success',
                title: 'Successfully save on bookmark'
            })
        },
        toBookmark(){
            this.bookmarkParse = this.$route.params.bookmark
            let target = "surah-"+this.bookmarkParse
            setTimeout(function() {
                let el = document.getElementById(target)
    
                el.scrollIntoView({ behavior: 'smooth'})
            }, 1000);
        }
    }
}
</script>

<style>

</style>